import blackArrow from 'resources/images/black-arrow.svg';

const AdBanner = () => {
  return (
    <a
      className="w-ful  z-20 top-banner flex items-center justify-center gap-2 px-4 py-3"
      href="https://app.symbiotic.fi/vault/0x594380c06552A4136E2601F89E50b3b9Ad17bd4d"
      rel="noreferrer"
      target="_blank">
      <div className="text-[16px] font-[500] text-black">
        Coming soon: New MANTA staking system goes live in late April!
        <br />
        Pre-staking via Symbiotic is now open. Unstake your MANTA and click here
        to get started with pre-staking.
      </div>
      <img src={blackArrow} alt="" />
    </a>
  );
};

export default AdBanner;
